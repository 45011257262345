<script setup>
import { useLocale } from "vuetify";

const { t } = useLocale();
</script>
<template>
  <v-container fluid class="pt-5 pb-5" :class="[{ 'bg-light': tbackground }]">
    <h2 class="text-uppercase text-colored" v-if="title != null">
      {{ title }}
    </h2>
    <hr class="col-2 mx-auto mb-5 hr-colored" v-if="title != null" />
    <v-row class="justify-center">
      <div
        class="col-lg-4 col-md-3 col-6 d-flex"
        v-for="product in products"
        :key="product.id"
      >
        <div class="card flex-fill m-1 bg-transparent border-0 overflow-hidden">
          <div class="card-title">
            <v-img
              :src="product.productMainImage"
              class="mb-2 align-end card-img product-img img-fluid"
            ></v-img>
          </div>
          <div class="card-body">
            <div class="d-sm-flex flex-column">
              <h6 class="text-left">
                <router-link
                  :to="'/product/details/' + product.slug"
                  class="nav-link"
                >
                  {{ product.productName }}
                </router-link>
              </h6>
              <div class="col-12">
                <h5
                  class="ml-auto text-colored"
                  :class="[
                    { 'text-left': $vuetify.locale.current == 'ar' },
                    { 'text-left': $vuetify.locale.current == 'en' },
                  ]"
                >
                  <span
                    v-if="!product.isSizeable && product.discountPrice == null"
                    :class="[{ 'line-through': product.discountPrice != null }]"
                    >{{ product.price }} LYD</span
                  >
                  <span
                    class="d-block"
                    v-if="!product.isSizeable && product.discountPrice != null"
                    >{{ product.discountPrice }} LYD</span
                  >
                  <sup
                    v-if="!product.isSizeable && product.discountPrice != null"
                    :class="[{ 'line-through': product.discountPrice != null }]"
                    >{{ product.price }} LYD</sup
                  >
                </h5>
              </div>

              <div class="col-12">
                <h5
                  class="ml-auto text-colored"
                  :class="[
                    { 'text-left': $vuetify.locale.current == 'ar' },
                    { 'text-left': $vuetify.locale.current == 'en' },
                  ]"
                >
                  <span
                    v-if="
                      product.isSizeable &&
                      product.sizePricing[0].discountPrice == null
                    "
                    :class="[
                      {
                        'line-through':
                          product.sizePricing[0].discountPrice != null,
                      },
                    ]"
                    >{{ product.sizePricing[0].price }} LYD</span
                  >
                  <span
                    class="d-block"
                    v-if="
                      product.isSizeable &&
                      product.sizePricing[0].discountPrice != null
                    "
                    >{{ product.sizePricing[0].discountPrice }} LYD</span
                  >
                  <sup
                    v-if="
                      product.isSizeable &&
                      product.sizePricing[0].discountPrice != null
                    "
                    :class="[
                      {
                        'line-through':
                          product.sizePricing[0].discountPrice != null,
                      },
                    ]"
                    >{{ product.sizePricing[0].price }} LYD</sup
                  >
                </h5>
              </div>
            </div>
            <div>
              <div class="row flex-column">
                <h5>
                  <a
                    :href="'/product/shop/' + product.companySlug"
                    class="text-colored"
                  >
                    <i
                      class="text-decoration-none"
                      :class="[
                        { 'float-lg-start': $vuetify.locale.current == 'en' },
                        { 'float-lg-end': $vuetify.locale.current == 'ar' },
                      ]"
                      >{{ product.companyName }}</i
                    >
                  </a>
                </h5>

                <h6 class="d-block">
                  <i
                    :class="[
                      { 'float-start': $vuetify.locale.current == 'en' },
                      { 'float-end': $vuetify.locale.current == 'ar' },
                    ]"
                    >{{ product.category }}</i
                  >
                </h6>
              </div>
            </div>
          </div>
          <div class="card-footer bg-transparent border-0">
            <v-btn
              v-if="!product.isSizeable"
              flat
              class="ml-auto text-white bg-colored"
              :class="[
                { 'mt-4': product.discountPrice == null },
                { 'float-start': $vuetify.locale.current == 'ar' },
                { 'float-end': $vuetify.locale.current == 'en' },
              ]"
              @click="addToCart(product, null)"
            >
              <font-awesome-icon icon="fas fa-cart-shopping" />
              {{ t("$vuetify.cart.add") }}
            </v-btn>
            <v-btn
              v-if="product.isSizeable"
              flat
              class="ml-auto text-white bg-colored"
              :class="[
                { 'mt-4': product.discountPrice == null },
                { 'float-start': $vuetify.locale.current == 'ar' },
                { 'float-end': $vuetify.locale.current == 'en' },
              ]"
              @click="addToCart(product, 30)"
            >
              <font-awesome-icon icon="fas fa-cart-shopping" />
              {{ t("$vuetify.cart.add") }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      item: [],
    };
  },
  methods: {
    addToCart(card, payload) {
      if (payload == null) {
        this.$store.commit("addToCart", { product: card, size: null });
      } else {
        this.$store.commit("addToCart", { product: card, size: payload });
      }
    },
    removeFromCart(card, payload) {
      this.$store.commit("removeFromCart", { product: card, size: payload });
    },
  },
  props: {
    title: String,
    products: Array,
    background: Boolean,
  },
};
</script>
